import * as React from 'react';
import { Typography } from '@mui/material';

import './NotFound';

const NotFound: React.FC = () => {
    return (
        <Typography>Not found page</Typography>
    );
};

export default NotFound;